import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import {
  FIREBASE_COLLECTIONS_NAMES,
  MESSAGES,
} from '../shared/contants/config';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userData: any; // Save logged in user data // User data in firebase authentication
  public logged_in_user: any // User data in firestore
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService
  ) {
    this.afAuth.setPersistence('session')
    /** Saving user data in localstorage when 
    logged in and setting up null when logged out  */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        const userExist = JSON.parse(localStorage.getItem('isUserValid'));
        if (!userExist) {
          this.checkAdminRole(this.userData.email);
        }
        this.logged_in_user = JSON.parse(localStorage.getItem('loggedInUser'));
      } else {
        localStorage.setItem('user', JSON.stringify(null));
        localStorage.setItem('isUserValid', JSON.stringify(false));
        this.router.navigate(['auth/login'])
      }
    });
  }

  get user() {
    return this.userData
  }
  get loggedInUser() {
    return this.logged_in_user
  }
  /** SignIn with email and password */
  loginViaEmailAndPass(email: string, password: string) {
    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.checkAdminRole(email);
       })
      .catch((error) => {
        // Stop the foreground loading
        this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        this.showErrorMessage(error.message, MESSAGES.UNAUTHORIZED_LOGIN_TITLE);
      });
  }

  /** Reset Forggot password */
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert(MESSAGES.FORGOT_PASSWORD_SUCCESS_MSG);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  /** Reset password */
  resetPassword(password: string) {
    return this.afAuth.currentUser
      .then((user) => {
        return user.updatePassword(password)

      })
    // .sendPasswordResetEmail(password)
    // .then(() => {
    //   window.alert(MESSAGES.FORGOT_PASSWORD_SUCCESS_MSG);
    // })
    // .catch((error) => {
    //   window.alert(error);
    // });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  isLoggedInUser(): boolean {
    return JSON.parse(localStorage.getItem('isUserValid'))
  }

  loginViaGoogle() {
    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
    return this.afAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((result) => { })
      .catch((error) => {
        // Stop the foreground loading
        this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        this.showErrorMessage(error.message, MESSAGES.UNAUTHORIZED_LOGIN_TITLE);
      });
  }

  loginViaFacebook() {
    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
    return this.afAuth
      .signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .then((result) => { })
      .catch((error) => {
        // Stop the foreground loading
        this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        this.showErrorMessage(error.message, MESSAGES.UNAUTHORIZED_LOGIN_TITLE);
      });
  }

  // this method will check whether a logged in used is admin or not
  checkAdminRole(email: string) {
    const docRef = this.getData(email);
    docRef.snapshotChanges().forEach((changes) => {
      if (changes && changes.length) {
        changes.map((a) => {
          const id = a.payload.doc.id;
          this.afs
            .collection(`/${FIREBASE_COLLECTIONS_NAMES.userRole}`)
            .doc(id)
            .get()
            .subscribe((data) => {
              if (data.exists) {
                // Stop the foreground loading
                this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
                let validUser = <any>data.data();
                console.log("Saving user data locally: ",validUser);
                localStorage.setItem('loggedInUser', JSON.stringify(validUser)) // Save logged in user data in localstorage
                this.ngZone.run(() => {
                  localStorage.setItem(
                    'isUserValid',
                    validUser.roleId ? 'true' : 'false'
                  );
                  if (validUser.roleId) {
                    this.router.navigate(['dashboard']);

                    this.logged_in_user = JSON.parse(localStorage.getItem('loggedInUser'));
                    console.log(window.location.href.split('/')[4])
                    if(window.location.href.split('/')[4] == "dashboard"  ){
                      window.location.reload()

                    }
                  } else {
                    // not an admin
                    this.showErrorMessage(
                      MESSAGES.PERMISSION_DENIED_MSG,
                      MESSAGES.UNAUTHORIZED_LOGIN_TITLE
                    );
                    localStorage.setItem('isUserValid', 'false');
                    this.router.navigate(['/auth/login']);
                  }
                  // this.router.navigate(['dashboard']);
                });
              } else {
                // Stop the foreground loading
                this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
                localStorage.setItem('isUserValid', 'false');
                this.router.navigate(['auth/login']);
              }
            });
        });
      } else {
        // no such user created in UserRole collection
        this.showErrorMessage(
          MESSAGES.PERMISSION_DENIED_MSG,
          MESSAGES.UNAUTHORIZED_LOGIN_TITLE
        );
        // Stop the foreground loading
        this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
        localStorage.setItem('isUserValid', 'false');
        this.router.navigate(['auth/login']);
      }
    });
  }

  getData(email: string) {
    const docRef = this.afs.collection(
      FIREBASE_COLLECTIONS_NAMES.userRole,
      (ref) => ref.where('email', '==', email)
    );
    return docRef;
  }

  /** This method is used to logout from socially logged in account (google and facebook) */
  logout() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      window.indexedDB.deleteDatabase('firebaseLocalStorageDb');

      this.router.navigate(['/auth/login']);
    });
  }

  showErrorMessage(message: string, title: string) {
    this.toastr.error(message, title);
  }
}
