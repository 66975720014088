export interface ISIDEMENU {
  menu: string;
  url: string;
  icon: string;
  children: ISIDEMENU[];
  active: boolean;
}

export const adminNavItem: ISIDEMENU[] = [
  {
    menu: 'Dashboard',
    url: '/dashboard',
    icon: 'fa-tachometer-alt',
    children: [],
    active: false,
  },
  {
    menu: 'User Management',
    url: '/user-management',
    icon: 'fa-users',
    children: [],
    active: false,
  },
  {
    menu: 'Product Management',
    url: '/product-management',
    icon: 'fa-box-open',
    children: [],
    active: false,
  },
  // {
  //   menu: 'Transactions',
  //   url: '/transactions',
  //   icon: 'fa-exchange-alt',
  //   children: [],
  //   active: false,
  // },
  {
    menu: 'Filter',
    url: '/filter-management',
    icon: 'fa-filter',
    children: [],
    active: false,
  },
  {
    menu: 'Blogs',
    url: '/blogs',
    icon: 'fa-blog',
    children: [],
    active: false,
  },
  {
    menu: 'Settings',
    url: '/settings',
    icon: 'fa-cog',
    children: [],
    active: false,
  },
  {
    menu:'Subscription Plans',
    url: '/subscription-plans',
    icon: 'fa-dollar',
    children: [],
    active: false,
  },
  {
    menu:'Experts',
    url: '/experts',
    icon: 'fa-users',
    children: [],
    active: false,
  },
  {
    menu: 'Services',
    url: '/experts-services',
    icon: 'fa-gears',
    children: [],
    active: false,
  },
  {
    menu: 'Coupon Codes',
    url: '/coupon-codes',
    icon: 'fa-gears',
    children: [],
    active: false,
  },
  //   {
  //     menu: 'Home',
  //     url: '/dashboard', // TODO: change this when url is decided
  //     icon: 'flaticon-381-home',
  //     // icon: '../../../../assets/images/svg/all_horses__icon/Home/SVG/Asset25.svg',
  //     children: [],
  //     active: false,
  //   },
  //   {
  //     menu: 'Charts',
  //     url: '/dashboard', // TODO: change this when url is decided
  //     icon: 'flaticon-004-bar-chart',
  //     // icon: '../../../../assets/images/svg/all_horses__icon/Chart/SVG/Asset24.svg',
  //     children: [],
  //     active: false,
  //   },
  //   {
  //     menu: 'Favorites',
  //     url: '/dashboard', // TODO: change this when url is decided
  //     icon: 'flaticon-029-star',
  //     // icon: '../../../../assets/images/svg/all_horses__icon/Fvrt/SVG/Asset23.svg',
  //     children: [],
  //     active: false,
  //   },
  //   {
  //     menu: 'Chat',
  //     url: '/dashboard', // TODO: change this when url is decided
  //     icon: 'flaticon-160-chat',
  //     // icon: '../../../../assets/images/svg/all_horses__icon/chat/SVG/Asset22.svg',
  //     children: [],
  //     active: false,
  //   },
  //   {
  //     menu: 'Setting',
  //     url: '/dashboard', // TODO: change this when url is decided
  //     icon: 'flaticon-073-settings',
  //     // icon: '../../../../assets/images/svg/all_horses__icon/settings/SVG/Asset21.svg',
  //     children: [],
  //     active: false,
  //   },
  //   {
  //     menu: 'Help',
  //     url: '/dashboard', // TODO: change this when url is decided
  //     icon: 'flaticon-056-information',
  //     // icon: '../../../../assets/images/svg/all_horses__icon/help/SVG/Asset20.svg',
  //     children: [],
  //     active: false,
  //   },
];
