import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuardGuard implements CanActivate {
  constructor(private db:  AngularFirestore, private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot, ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

            const isValid = <string>localStorage.getItem('isUserValid') ;
            
            // return isValid == 'true' ? true : false;
            if (!isValid) {
              this.router.navigate(['/auth']);
              return false;
            } else {
              return true;
            }
  }

  
}
