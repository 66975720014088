import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateConfigService } from 'src/app/core/services/translate/translate-config.service';
import { UserManagementService } from 'src/app/feature-modules/user-management/user-management.service';
import { USERS } from '../../contants/interfaces/user-management.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public toggle: boolean = false;
  public userDisplayName: string = '';
  public userPhotoURL: string = '';
  public current_language: string;
  userList: USERS[];
  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    private translateService: TranslateConfigService,
    private router: Router,
    private userService:UserManagementService
  ) {
    const user = JSON.parse(localStorage.getItem('user')!);
    if (user) {
      this.userPhotoURL = user['photoURL'] ? user['photoURL'] : '';
    }
    const logged_in_user = JSON.parse(localStorage.getItem('loggedInUser')!);
    if (logged_in_user) {
      this.userDisplayName = logged_in_user['userName']
        ? logged_in_user['userName']
        : '';
    }
  }

  ngOnInit(): void {
    this.getLanguage();
    this.getUserList()
  }
  getUserList() {
    const logged_in_user = JSON.parse(localStorage.getItem('loggedInUser')!);
    this.userService.adminListData.subscribe(
      (data: USERS[]) => {
        if (data && data.length) {
          this.userList = [...data];
          console.log( this.userList);
          this.userList.find(i=>{
           if(i.id == logged_in_user['id']){
            this.userDisplayName = i.userName
           }
          })
          
        }
      },
      (err) => {
        console.log(err);
        
      }
    );
  }
  toggleMobileView() {
    this.toggle = !this.toggle;
    const element = <HTMLElement>document.querySelector('.show');
    if (this.toggle) {
      if (element) {
        this.renderer.addClass(element, 'menu-toggle');
      }
    } else {
      if (element) {
        this.renderer.removeClass(element, 'menu-toggle');
      }
    }
  }

  changeLanguage(language: string) {
    this.translateService.changeLanguage(language).subscribe((res) => {
      localStorage.setItem('language', language);
      this.current_language = language;
    });
  }
  getLanguage() {
    this.current_language = this.translateService.getLanguage();
    console.log('current_language ', this.current_language);
  }

  logout() {
    this.authService.logout();
  }
  openProfileInfo() {
    this.router.navigate(['profile']);
  }
}
