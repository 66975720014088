<!-- <div class="modal fade show" id="exampleModalCenter" style="display: block; padding-right: 15px;" aria-modal="true">
    <div class="modal-dialog modal-dialog-centered"> -->
<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" translate> admin.confirmation_popup.confirm </h5>
        <button type="button" class="close" data-dismiss="modal"><span>×</span>
        </button>
    </div>
    <div class="modal-body">
        <p> {{ title | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger light" data-dismiss="modal" (click)="onCancel()">{{ closeButtonText
            | translate }}</button>
        <button type="button" class="btn btn-primary confirmation-buttom" (click)="onConfirm()">{{ confirmButtonText |
            translate }}</button>
    </div>
</div>
<!-- </div> -->
<!-- </div> -->