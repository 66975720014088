<button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button">
    &times;
</button>
<div *ngIf="title" class="{{options.titleClass}}" [attr.aria-label]="title">
    {{ title | translate }}
</div>
<div *ngIf="message && options.enableHtml" class="{{options.messageClass}}" [innerHTML]="message">
</div>
<div *ngIf="message && !options.enableHtml" class="{{options.messageClass}}" [attr.aria-label]="message">
    {{ message | translate }}
</div>
<div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width.%]="width"></div>
</div>