import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateConfigService } from 'src/app/core/services/translate/translate-config.service';
import { adminNavItem, ISIDEMENU } from '../../contants/sidemenu.model';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {
  public menuItems: ISIDEMENU[] = [];

  constructor(private router: Router, private translateService: TranslateConfigService) { }

  ngOnInit(): void {
    this.menuItems = [...adminNavItem];
    // console.log(
    //   '🚀 ~ file: sidemenu.component.ts ~ line 18 ~ SidemenuComponent ~ ngOnInit ~ this.menuItems',
    //   this.menuItems
    // );
  }

  redirectToPage(
    $event: any,
    parentMenu: ISIDEMENU,
    childMenu: ISIDEMENU,
    menuUrl: string
  ) {
    $event.stopPropagation();
    parentMenu.active = true;
    childMenu.active = true;
    this.router.navigate([menuUrl]);
  }

  showMenu(menu: ISIDEMENU) {
    if (this.menuItems && this.menuItems.length) {
      this.menuItems.forEach((m, i) => {
        if (m.menu === menu.menu) m.active = !m.active;
        else m.active = false;
      });
    }
  }
}
