<div class="authincation error-container h-100">
    <div class="container not-found h-100">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-md-8">
                <div class="form-input-content text-center error-page">
                    <h1 class="error-text font-weight-bold">404</h1>
                    <h4 translate><i class="fa fa-exclamation-triangle text-warning"></i> admin.404.main_text </h4>
                    <p translate> admin.404.sub_text </p>
                    <div>
                        <a class="btn btn-primary" [routerLink]="'/auth/login'" translate>
                            admin.404.back_to_home_button_text </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>