import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MapService } from 'src/app/core/services/map/map.service';
import { DEFAULT_LOCATION } from '../../contants/config';
import { LATLONG } from '../../contants/interfaces/product-management.model';

@Component({
  selector: 'app-location-picker-map',
  templateUrl: './location-picker-map.component.html',
  styleUrls: ['./location-picker-map.component.scss']
})
export class LocationPickerMapComponent implements OnInit {
  @Input() location: LATLONG;
  @Input() is_editing: boolean = true
  @Output() locationChangeEvent = new EventEmitter()

  map_options: google.maps.MapOptions = {
    zoom: 12,
    streetViewControl: false,
    center: DEFAULT_LOCATION,
  }
  map_view = {
    height: '100%',
    width: 300
  }

  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
    position: DEFAULT_LOCATION
  };

  constructor(private _mapService: MapService) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes)
    if (changes['location'].previousValue != changes['location'].currentValue) {
      this.map_options.center = this.location
      this.markerOptions.position = this.location
    }
  }



  markerDragEndHandler(e) {
    console.log(e)
    this.location = e.latLng.toJSON()
    this.locationChangeEvent.emit(this.location)
    // this.getAddress()
  }

  getAddress() {
    this._mapService.getAddressFromLatLong(this.location.lat, this.location.lng).subscribe(result => {
      console.log("getAddress ", result)
    })
  }


}
