import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

import { BehaviorSubject, Observable } from 'rxjs';
import { FIREBASE_COLLECTIONS_NAMES } from 'src/app/shared/contants/config';
import { USERS } from 'src/app/shared/contants/interfaces/user-management.model';

const getObservable = (collection: AngularFirestoreCollection<USERS>) => {
  const subject = new BehaviorSubject<USERS[]>([]);
  collection.valueChanges({ idField: 'id' }).subscribe((val: USERS[]) => {
    subject.next(val);
  });
  return subject;
};

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  public users = getObservable(this.store.collection(FIREBASE_COLLECTIONS_NAMES.users)) as Observable<
    USERS[]
  >;
  public admin_users = getObservable(this.store.collection(FIREBASE_COLLECTIONS_NAMES.userRole)) as Observable<
    USERS[]
  >;
//   public payNl = getObservable(this.store.collection("/PayNl")) as Observable<
//   any
// >;
  public userList = new BehaviorSubject<USERS[]>([]);
  public admin_list = new BehaviorSubject<USERS[]>([]);
  // public payNl_list = new BehaviorSubject<USERS[]>([]);


  constructor(
    private store: AngularFirestore,
    private notification: ToastrService,
    private fb_auth: AngularFireAuth
  ) {
    this.users.subscribe(
      (data: USERS[]) => {
        if (data && data.length) {
          this.userList.next([...data]);
        }
      },
      (err) => {
        this.notification.error(err.message, 'Error');
      }
    );

    // Get admin users
    this.admin_users.subscribe(
      (data: USERS[]) => {
        if (data && data.length) {
          this.admin_list.next([...data]);
        }
      },
      (err) => {
        this.notification.error(err.message, 'Error');
      }
    );
  }

  // this method is used to update an existing firebase document of Users collection
  updateUser(data: USERS) {
    return this.store.collection(FIREBASE_COLLECTIONS_NAMES.users).doc(data.id).update(data);
  }

  // updateAdminUser(data: USERS) {
  //   return this.store.collection(FIREBASE_COLLECTIONS_NAMES.userRole).doc(data.id).update(data);
  // }
  updateAdminUser(data: any) {

    data['id'] = data.id
    return this.store
      .collection(FIREBASE_COLLECTIONS_NAMES.userRole)
      .doc(data.id)
      .update(data);
  }

  // this method is used to add a new user in firebase Users collection
  addUser(data: USERS) {
    const id = this.store.createId();
    data['id'] = id;

    return this.store
      .collection(FIREBASE_COLLECTIONS_NAMES.users)
      .doc(id)
      .set(data);
    // return this.store.collection(FIREBASE_COLLECTIONS_NAMES.users).add(data);
  }

  // this method is used to add a new admin user in firebase UserRole collection
  addAdminUser(data: USERS) {
    const id = this.store.createId();
    data['id'] = id;

    return this.store
      .collection(FIREBASE_COLLECTIONS_NAMES.userRole)
      .doc(id)
      .set(data);
    // return this.store.collection(FIREBASE_COLLECTIONS_NAMES.users).add(data);
  }

  // this getter method is used to get the users list
  get userListData() {
    return this.userList;
  }
  get adminListData() {
    return this.admin_list;
  }

  compareValues(key: string, order = 'asc') {
    return function innerSort(a: any, b: any) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  // 
  createUser(email: string, password: any) {
    return this.fb_auth.createUserWithEmailAndPassword(email, password)
  }

  getUserData(userId: string) {
    return this.store.collection(FIREBASE_COLLECTIONS_NAMES.users).doc(userId).valueChanges();
  }
}
