import { Component } from '@angular/core';
import Quill from 'quill';

var Link = Quill.import('formats/link');
Link.sanitize = function (url) {
  if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
    return url
  }
  else {
    return 'https://' + url
  }

}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AllHorses-Admin';
}
