import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { DashboardGuardGuard } from './shared/guards/dashboard-guard.guard';
import { SidemenuResolver } from './shared/resolvers/sidemenu.resolver';
const routes: Routes = [
  // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canActivate: [DashboardGuardGuard],
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  //  {
  //   path: 'transactions',
  //   canActivate: [DashboardGuardGuard],
  //   loadChildren: () => import('./feature-modules/transactions/transactions.module').then((m) => m.TransactionsModule),
  // },
  { path: 'login', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
