import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userActiveFilter'
})
export class UserActiveFilterPipe implements PipeTransform {

  transform(data: any, key: string, active: any, inactive: any): any {
    if (!data || data.length === 0) {
      return [];
    }
    if (active === inactive) {
      return data
    } else {
      if (active) {
        return data.filter(item => (item[key] == true));
      } else {
        return data.filter(item => (item[key] == false));
      }

    }

  }

}
