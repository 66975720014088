import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { SearchUsersPipe } from './pipes/search-users/search-users.pipe';
import { SearchProductsPipe } from './pipes/search-products/search-products.pipe';
import { SearchBlogsPipe } from './pipes/search-blogs/search-blogs.pipe';
import { UserActiveFilterPipe } from './pipes/user-active-filter/user-active-filter.pipe';
import { AgeFromMillisecondPipe } from './pipes/age-from-millisecond/age-from-millisecond.pipe';

@NgModule({
  declarations: [
    SearchUsersPipe,
    SearchProductsPipe,
    SearchBlogsPipe,
    UserActiveFilterPipe,
    AgeFromMillisecondPipe,
 
  ],
  imports: [CommonModule, ComponentsModule],
  exports: [
    ComponentsModule,
    SearchUsersPipe,
    SearchProductsPipe,
    SearchBlogsPipe,
    UserActiveFilterPipe,
    AgeFromMillisecondPipe,
  ],
})
export class SharedModule {}
