import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'ageFromMillisecond'
})
export class AgeFromMillisecondPipe implements PipeTransform {

  transform(value: number,): unknown {
    return moment().diff(moment(value), 'years');
  }

}
