import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { LayoutComponent } from './layouts/layout/layout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LocationPickerMapComponent } from './location-picker-map/location-picker-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateToastrComponent } from './translate-toastr/translate-toastr.component';

@NgModule({
  declarations: [PageNotFoundComponent, HeaderComponent, SidemenuComponent, ConfirmationPopupComponent, LayoutComponent, ResetPasswordComponent, LocationPickerMapComponent, TranslateToastrComponent],
  imports: [
    CommonModule,
    RouterModule,
    GoogleMapsModule,
    TranslateModule
  ],
  exports: [HeaderComponent, SidemenuComponent, ConfirmationPopupComponent, LayoutComponent, ResetPasswordComponent, LocationPickerMapComponent],
  entryComponents: [ConfirmationPopupComponent]
})
export class ComponentsModule { }
