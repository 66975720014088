import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  constructor(private translateService: TranslateService) {
    this.translateService.defaultLang = 'en'
    let lang = localStorage.getItem('language') || 'en'
    this.translateService.use(lang)
  }

  changeLanguage(lang: string) {
    return this.translateService.use(lang)
  }
  getLanguage() {
    return this.translateService.currentLang
  }
}
