import { ModalOptions } from 'ngx-bootstrap/modal';
import { DASHBOARD_HOME_CARD } from './interfaces/dashboard.model';
import { LATLONG } from './interfaces/product-management.model';

export const POP_UP_CONFIG: ModalOptions = {
  animated: true,
  backdrop: 'static',
  class: 'modal-dialog-centered',
};

export const EMAIL_REGEX =
  /^\w+([`\’.'-]?\w+)*(-)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/;
export const PHONE_NUMBER_REGEX = /[- +()0-9]+/;
export const ONLY_NUMBER = /^\d+$/;
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
/**Message constants used to show in toaster */
export const MESSAGES = {
  SUCCESS_TITLE: 'Success',
  UNAUTHORIZED_LOGIN_TITLE: 'Unauthorized Login',
  PERMISSION_DENIED_MSG: 'Permission Denied.',
  FORGOT_PASSWORD_SUCCESS_MSG: 'Password reset email sent, check your inbox.',

  UPDATE_PASSWORD_SUCCESS_TITLE: 'Update password success',
  UPDATE_PASSWORD_ERROR_TITLE: 'Update password error',

  UPDATE_PASSWORD_SUCCESS_MSG: 'Successfully updated your password.',
  UPDATE_PASSWORD_ERROR_MSG:
    'Unexpected error happened. Refresh the page and try again.',

  ADD_BLOG_SUCCESS_MSG: 'Created a new blog',
  UPDATE_BLOG_SUCCESS_MSG: 'Updated blog',
  PUBLISH_BLOG_SUCCESS_MSG: 'Successfully published the blog.',
  UNPUBLISH_BLOG_SUCCESS_MSG: 'Unpublished the blog.',
  ADD_USER_SUCCESS_MSG: 'User created successfully.',
  EDIT_USER_SUCCESS_MSG: 'User updated successfully.',
  USER_ENABLED_SUCCESS_MSG: 'User enabled successfully.',
  USER_DISABLED_SUCCESS_MSG: 'User disabled successfully.',
  USER_ALREADY_EXISTS_ERROR_MSG: 'Please try again with different User ID.',
  USER_ALREADY_EXISTS_ERROR_TITLE: 'User ID Already Exists.',
  USER_DELETE_MSG: 'User deleted successfully.',
  USER_DELETE_ERROR_MSG: 'Something went wrong.',
  ADD_PRODUCT_SUCCESS_MSG: 'Product created successfully.',
  EDIT_PRODUCT_SUCCESS_MSG: 'Product updated successfully.',
  DELETE_PRODUCT_SUCCESS_MSG: 'Product deleted successfully.',
  ADD_BREED_SUCCESS_MSG: 'Breed added successfully.',
  EDIT_BREED_SUCCESS_MSG: 'Breed updated successfully.',
  DELETE_BREED_SUCCESS_MSG: 'Breed deleted successfully.',
  BREED_ALREADY_EXISTS_ERROR_MSG: 'Please try again with different breed name.',
  BREED_ALREADY_EXISTS_ERROR_TITLE: 'Breed Already Exists.',
  ADD_COLOR_SUCCESS_MSG: 'Color added successfully.',
  EDIT_COLOR_SUCCESS_MSG: 'Color updated successfully.',
  DELETE_COLOR_SUCCESS_MSG: 'Color deleted successfully.',
  COLOR_ALREADY_EXISTS_ERROR_MSG: 'Please try again with different color name.',
  COLOR_ALREADY_EXISTS_ERROR_TITLE: 'Color Already Exists.',

  ADD_CATEGORY_SUCCESS_MSG: 'Category added successfully.',
  EDIT_CATEGORY_SUCCESS_MSG: 'Category updated successfully.',
  DELETE_CATEGORY_SUCCESS_MSG: 'Category deleted successfully.',
  CATEGORY_ALREADY_EXISTS_ERROR_MSG:
    'Please try again with different Category name.',
  CATEGORY_ALREADY_EXISTS_ERROR_TITLE: 'Category Already Exists.',

  ADD_STUDBOOK_SUCCESS_MSG: 'Studbook added successfully.',
  EDIT_STUDBOOK_SUCCESS_MSG: 'Studbook updated successfully.',
  DELETE_STUDBOOK_SUCCESS_MSG: 'Studbook deleted successfully.',
  STUDBOOK_ALREADY_EXISTS_ERROR_MSG:
    'Please try again with different Studbook name.',
  STUDBOOK_ALREADY_EXISTS_ERROR_TITLE: 'Studbook Already Exists.',

  ADD_EXAMINATION_SUCCESS_MSG: 'Examination added successfully.',
  EDIT_EXAMINATION_SUCCESS_MSG: 'Examination updated successfully.',
  DELETE_EXAMINATION_SUCCESS_MSG: 'Examination deleted successfully.',
  EXAMINATION_ALREADY_EXISTS_ERROR_MSG:
    'Please try again with different Examination name.',
  EXAMINATION_ALREADY_EXISTS_ERROR_TITLE: 'Examination Already Exists.',

  ADD_GENDER_SUCCESS_MSG: 'Gender added successfully.',
  EDIT_GENDER_SUCCESS_MSG: 'Gender updated successfully.',
  DELETE_GENDER_SUCCESS_MSG: 'Gender deleted successfully.',
  GENDER_ALREADY_EXISTS_ERROR_MSG:
    'Please try again with different Gender name.',
  GENDER_ALREADY_EXISTS_ERROR_TITLE: 'Gender Already Exists.',

  ADD_LEVEL_SUCCESS_MSG: 'Level added successfully.',
  EDIT_LEVEL_SUCCESS_MSG: 'Level updated successfully.',
  DELETE_LEVEL_SUCCESS_MSG: 'Level deleted successfully.',
  LEVEL_ALREADY_EXISTS_ERROR_MSG: 'Please try again with different Level name.',
  LEVEL_ALREADY_EXISTS_ERROR_TITLE: 'Level Already Exists.',

  FILTER_ADD_ERROR: 'Error while adding filter. Please try again',
  FILTER_EDIT_ERROR: 'Error while updating filter. Please try again',
  FILTER_DELETE_ERROR: 'Error while deleting filter. Please try again',

  BLOG_DELETE_MSG: 'Blog deleted successfully.',
  BLOG_DELETE_ERROR_MSG: 'Something went wrong.',

  ADD_DOCUMENT_SUCCESS_MSG: 'Document created successfully.',
  EDIT_DOCUMENT_SUCCESS_MSG: 'Docmument updated successfully.',
  DOCUMENT_DELETE_MSG: 'Document deleted successfully.',
  DOCUMENT_DELETE_ERROR_MSG: 'Something went wrong.',
  ADD_PLAN_SUCCESS_MSG: 'Plan created successfully',
  EDIT_PLAN_SUCCESS_MSG: 'Plan updated successfully',
  DELETE_PLAN_SUCCESS_MSG: 'Plan deleted sucessfully',
  EDIT_PROFILE_SUCCESS_MSG: 'Profile Updated Successfully',
  UPDATED_ANDROID_VERSION: 'Sucessfully Updated Android Version',
  UPDATED_VERSION: 'Sucessfully Version Updated',
  INVITATION_SENT: 'Sucessfully Invitation Sent',
  EXPERT_ADDED_SUCCESS: 'Expert Added Sucessfully',
  EXPERT_UPDATED_SUCCESS: 'Expert Updated Sucessfully',
  EXPERT_DELETED_SUCCESS: 'Expert Deleted Sucessfully',
  SERVICE_ADDED_SUCCESS: 'Service Added Sucessfully',
  COUPON_ADDED_SUCCESS: 'Coupon Code Added Sucessfully',
  COUPON_UPDATE_SUCCESS: 'Coupon Code Updated Sucessfully',
  NOT_ALLOWED: 'Action Not Allowed',
  NO_CALENDLY_URI:
    'Expert is not added to you calendly organization! Please check expert status and try again.',
  SERVICE_UPDATED_SUCCESS: 'Service Updated Sucessfully',
  SERVICE_DELETED_SUCCESS: 'Service Deleted Sucessfully',
};

/**This will be used on dashboard home page to show management modules */
export const DASHBOARD_QUICK_LINKS: DASHBOARD_HOME_CARD[] = [
  // {
  //   icon: 'fa-search',
  //   title: 'Search User',
  //   action: 'search_user',
  //   cssClass: 'bg-blue-300'
  // },

  {
    icon: 'fa-plus',
    title: 'Add new advertisement',
    action: 'add_new_advertisement',
    cssClass: 'bg-blue-300',
  },
  {
    icon: 'fa-user-plus',
    title: 'Add User',
    action: 'add_user',
    cssClass: 'bg-red-400',
  },
];

export const DASHBOARD_HOME_PAGE: DASHBOARD_HOME_CARD[] = [
  {
    imageURL: 'assets/images/svg/all_horses__icon/User Mangmnt/SVG/Qz6gDR.svg',
    title: 'User Management',
    cssClass: 'user-management',
    redirectURL: 'dashboard/user-management',
  },
  {
    imageURL:
      'assets/images/svg/all_horses__icon/Prodct Managmnt/SVG/Asset2.svg',
    title: 'Product Management',
    cssClass: 'product-management',
    redirectURL: 'dashboard/product-management',
  },
  {
    imageURL: 'assets/images/svg/all_horses__icon/Filtr mngmt/SVG/wAbWpt.svg',
    title: 'Filter Management',
    cssClass: 'filter-management',
    redirectURL: 'dashboard/filter-management',
  },
];

export const ADD_PRODUCT_DROPDOWNS = {
  colors: [
    'Different',
    'Fur',
    'brown',
    'Dark brown',
    'Palomino',
    'Roan Brown',
    'mold',
    'Fox',
    'Black',
  ],
  studbook: [
    'Arabian thoroughbred',
    'BWP',
    'Danish Warmblood',
    'English Thoroughbred',
    'Frisian',
    'Groningen studbook',
    'Hanover',
    'Holstein',
    'Irish Sport Horse',
    'KWPN',
    'Lipizzaner',
    'NRPS',
    'Oldenburg',
    'PRE',
    'SBS',
    'Selle Francais',
    'Trakehning',
    'Westphalia',
    'Other',
    'Zangersheide',
    'Swedish Warmbloo',
  ],

  gender: ['Mule', 'Ruin', 'Stallion'],
  examination: ['Clinical', 'X-Ray', 'X-Ray and Clinical'],
  level: [
    'unlearned',
    '1.00m',
    '1.10m',
    '1.20m',
    '1.30m',
    '1.40m',
    'B',
    'International',
    'L',
    'M',
    'National',
    'Z',
    'Saddle up',
    'Z',
  ],
  categories: [
    {
      group: 'Offered HEAD CATEGORIES',
      value: [
        'Fokmerries',
        'Springpaarden',
        'Dressuurpaarden',
        'Recreatief',
        'Allround',
      ],
    },
    {
      group: 'Wanted HEAD CATEGORIES',
      value: [
        'Fokmerries',
        'Springpaarden',
        'Dressuurpaarden',
        'Recreatief',
        'Allround',
      ],
    },
  ],
};

export const FIREBASE_COLLECTIONS_NAMES = {
  // products: 'NewHorses', //
  products: 'HorseCollection',
  deletedOldProducts: 'DeletedOldHorseCollecton',
  breed: 'Breed',
  colors: 'Colors',
  users: 'Users',
  userRole: 'UserRole',
  blogs: 'Blogs',
  gender: 'Gender',
  level: 'Level',
  examination: 'Examination',
  categories: 'Categories',
  studbook: 'Studbook',
  documents: 'Documents',
  subscriptionPlans: 'SubscriptionPlans',
  latestAppVersion: 'LatestAppVersions',
  experts: 'Experts',
  // expertService: 'RYZExpertService', // TODO: Remove the RYZ
  expertService: 'ExpertService',
  purchasedServices: 'PurchasedServices',
  expertSpecilization: 'specialization',
  calendlyOrganizationUri: 'CalendlyOrganizationConfigs',
  couponCodes: 'CouponCodes',
  // payNl:'payNl'
};

export const DEFAULT_LOCATION: LATLONG = {
  lat: 52.1625105473292,
  lng: 5.373015015700929,
};
