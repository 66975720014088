<div class="deznav">
  <div class="deznav-scroll mm-active ps ps--active-y">
    <!-- <div class="main-profile">
      <img src="images/Untitled-1.jpg" alt="">
            <a href="javascript:void(0);"><i class="fa fa-cog" aria-hidden="true"></i></a>
            <h5 class="mb-0 fs-20 text-black "><span class="font-w400">Hello,</span> Marquez</h5>
            <p class="mb-0 fs-14 font-w400">marquezzzz@mail.com</p>
    </div> -->
    <ul class="metismenu mm-show" id="menu">
      <li *ngFor="let menu of menuItems; let i = index" (click)="showMenu(menu)"
        [ngClass]="{ 'mm-active': menu.active, '': !menu.active }">
        <ng-container *ngIf="menu.children.length != 0; else notNested">
          <a class="has-arrow ai-icon" href="javascript:void(0);" aria-expanded="false" [routerLink]="menu.url"
            data-toggle="tooltip" data-placement="bottom" title="{{ menu.menu | translate }}">
            <!-- <i class="flaticon-144-layout"></i> -->
            <img [src]="menu.icon" class="menu-image" />
            <span class="nav-text">{{ menu.menu | translate }}</span>
          </a>
          <ul aria-expanded="false" class="mm-collapse" [ngClass]="{ 'mm-show': menu.active, '': !menu.active }">
            <li class="child-menu-item" *ngFor="let child of menu.children; let j = index">
              <a (click)="redirectToPage($event, menu, child, child.url)"
                [ngClass]="{ 'mm-active': child.active, '': !child.active }" data-toggle="tooltip"
                data-placement="bottom" title="{{ child.menu }}">{{ child.menu }}</a>
            </li>
          </ul>
        </ng-container>
        <ng-template #notNested>
          <a [routerLink]="menu.url" class="ai-icon" aria-expanded="false" data-toggle="tooltip" data-placement="bottom"
            title="{{ menu.menu | translate }}">
            <!-- <i class="fas fa-tachometer-alt"></i> -->
            <i class="fas" [class]="menu.icon"></i>
            <!-- <img [src]="menu.icon" class="menu-image" /> -->
            <span class="nav-text">{{ menu.menu | translate }}</span>
          </a>
        </ng-template>
      </li>
    </ul>
    <!-- <div class="copyright">
            <p><strong>Zenix Crypto Admin Dashboard</strong> © 2021 All Rights Reserved</p>
            <p class="fs-12">Made with <span class="heart"></span> by DexignZone</p>
        </div> -->
    <div class="ps__rail-x" style="left: 0px; bottom: 0px">
      <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
    </div>
    <div class="ps__rail-y" style="top: 0px; height: 853px; right: 0px">
      <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 667px"></div>
    </div>
  </div>
</div>