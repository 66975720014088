// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB4wpHkkcumCfpLDT-7vngYTVWMOSvFI0g",
    authDomain: "allhorsesfirebase-dev-100.firebaseapp.com",
    projectId: "allhorsesfirebase-dev-100",
    storageBucket: "allhorsesfirebase-dev-100.appspot.com",
    messagingSenderId: "762007228284",
    appId: "1:762007228284:web:3489f5dd745900c3655ef9",
    measurementId: "G-CT3ZL4K4DF"
  },
  google: {
    gmap_key: 'AIzaSyDLEy8658U1i_rlidBmamPboE3rv1bVohc'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
