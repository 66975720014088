import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchProducts'
})
export class SearchProductsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!args) {
      return value;
    }
    return value.filter((val: any) => {
      let rVal = (val.name?.toLocaleLowerCase().includes(args))
        || (val.category?.toLocaleLowerCase().includes(args))
        || (val.studbook?.toLocaleLowerCase().includes(args));
      return rVal;
    })
  }

}
