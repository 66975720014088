import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {

  public title: string = 'Confirm';
  public confirmButtonText: string = "'Yes' || 'Check invitaion status' || 'Check expert available Time'||'OK ";
  public closeButtonText: string = "'No' || 'cancel'";
  public onClose: Subject<boolean>;

  constructor( private _bsModalRef: BsModalRef) { 
    this.onClose = new Subject();
  }

  ngOnInit(): void {
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this._bsModalRef.hide();
}

public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
}

}
